import React from "react"

// Styling
import colors from "../../styles/colors"

interface IProps {
  className?: string
  theme?: "dark" | "light"
}

const Logo = ({ className, theme = "dark" }: IProps) => (
  <svg
    className={className}
    viewBox="0 0 160 80"
    fill={theme === "dark" ? colors.white : colors.black}
  >
    <g fillRule="evenodd">
      <path d="M27.108 79.996l-.441.004H0V53.333h26.667V26.667h26.666v26.666c0 14.58-11.701 26.428-26.225 26.663z" />
      <path d="M53.333 26.667V0H80c14.58 0 26.428 11.701 26.663 26.226l.004.44v26.667H80V26.667H53.333z" />
      <path d="M106.667 26.667V0h26.666c14.58 0 26.428 11.701 26.663 26.226l.004.44v26.667h-26.667V26.667h-26.666z" />
    </g>
  </svg>
)

export default Logo
