import React from "react"
import styled, { css } from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"

// Utils
import { url } from "../../static/baseConfig"

// Components
import InstagramSVG from "./icons/social-instagram"
import FacebookSVG from "./icons/social-facebook"
import LinkedinSVG from "./icons/social-linkedin"

const SocialMediaIcons = () => (
  <Wrapper>
    <SocialItem>
      <SocialLink href={url.instagram} target="_blank">
        <InstagramSVG />
      </SocialLink>
    </SocialItem>

    <SocialItem>
      <SocialLink href={url.facebook} target="_blank">
        <FacebookSVG />
      </SocialLink>
    </SocialItem>

    <SocialItem>
      <SocialLink href={url.linkedin} target="_blank">
        <LinkedinSVG />
      </SocialLink>
    </SocialItem>
  </Wrapper>
)

export default SocialMediaIcons

// Shared styles
const SocialIconStyles = css`
  width: 100%;
  fill: ${colors.white};
  max-width: 15vw;
  max-height: 15vw;

  ${mq.from.M`
    max-width: 10vw;
    max-height: 10vw;
  `}
`

// Main styles
const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
`

const SocialItem = styled.li`
  margin-bottom: 0;
  line-height: 0;
  width: 100px;

  ${mq.from.M`
    width: 115px;
  `}

  ${mq.from.L`
    width: 150px;
  `}

  ${mq.from.XXL`
    width: auto;
    max-width: 300px;
  `}

  &:not(:last-child) {
    ${mq.from.M`
      margin-right: 3vw;
    `}
  }
`

const SocialLink = styled.a`
  display: block;

  &:hover {
    svg {
      fill: ${colors.accent1};
    }
  }

  svg {
    ${SocialIconStyles};
  }
`
