import { useState, useEffect } from "react"

// Utils
const isClient = typeof window !== "undefined"
const getSize = () => {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }
}

const UseWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (isClient) {
      const handleResize = () => {
        setWindowSize(getSize())
      }

      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

export default UseWindowSize
