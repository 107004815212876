import React from "react"
import styled from "styled-components"

// Components
import Footer from "./Footer"
import Header from "./header"

// Styling
import CSSReset from "../styles/reset"
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"

const Layout: React.FC = ({ children }) => (
  <>
    <CSSReset />
    <Header />
    <Container>
      <main>{children}</main>
      <Footer />
    </Container>
  </>
)

const Container = styled.div`
  margin: 0 auto;
  padding-top: 0;
  margin-top: 100px;
  background-color: ${colors.black};

  ${mq.from.M`
    margin-top: 120px;
  `}

  ${mq.from.L`
    margin-top: 150px;
  `}

  ${mq.from.XXL`
    margin-top: 180px;
  `}
`

export default Layout
