import React from "react"
import styled from "styled-components"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"
import { fonts } from "../styles/fonts"

const InfoFooter = () => (
  <FullWrapper>
    <InfoBlock>
      <InfoTitle>Let's talk!</InfoTitle>
      <InfoText>Want to know more about remote user research?</InfoText>
      <ExternalLink href={"tel:0205141414"}>
        Call +31 (0)20 514 14 14
      </ExternalLink>
    </InfoBlock>

    <InfoBlock>
      <InfoTitle>More cases</InfoTitle>
      <InfoText>Interested, but do you want to know more?</InfoText>
      <ExternalLink href="https://www.jungleminds.com/cases" target="_blank">
        See other cases
      </ExternalLink>
    </InfoBlock>
  </FullWrapper>
)

export default InfoFooter

// Main styles
export const FullWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin: 50px 0;

  ${mq.from.M`
    flex-direction: row;
  `}
`

const InfoBlock = styled.div`
  background-color: ${colors.accent2};
  color: ${colors.black};
  padding: 100px 50px;
  width: 100%;
  text-align: left;
  font-family: ${fonts.primary};

  &:nth-child(2) {
    background-color: ${colors.white};
  }

  ${mq.from.M`
    width: 50%;
  `}
`

const InfoTitle = styled.h3`
  font-family: ${fonts.primary};
  font-size: 45px;
  font-weight: bold;
`

const InfoText = styled.p`
  font-family: ${fonts.primary};
  font-size: 15px;
  font-weight: bold;
  height: 55px;
  margin-bottom: 50px;

  ${mq.from.L`
    height: auto;
  `}
`

const ExternalLink = styled.a`
  color: ${colors.black};
  text-decoration: none;
  transition: 0.5s;

  &:hover {
    text-decoration: underline;
  }
`
