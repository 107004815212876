import React, { PureComponent } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

// Components
import ContainerComponent from "./layout/Container"
import LogoComponent from "./icons/Logo"

// Styling
import { mq } from "../styles/utils/mq"
import { duration, ease } from "../styles/animations"
import zIndices from "../styles/zIndices"
import colors from "../styles/colors"
import { fonts } from "../styles/fonts"

class Header extends PureComponent {
  public render() {
    const path = typeof window !== "undefined" && window.location.pathname

    return (
      <Wrapper>
        <Container>
          <LogoLink
            href={"https://www.jungleminds.com"}
            target={"_blank"}
            aria-label="Jungle Minds Homepage"
            tabIndex={0}
          >
            <Logo />
          </LogoLink>

          {path !== "/" && (
            <Link to="/">
              <HeaderText>Go to the overview</HeaderText>
            </Link>
          )}
        </Container>
      </Wrapper>
    )
  }
}

export default Header

// Shared styles
const sharedSVGStyles = css`
  height: 24px;
  width: 50px;

  ${mq.from.M`
    height: 32px;
    width: 60px;
  `}

  ${mq.from.L`
    vertical-align: text-bottom;
    height: 48px;
    width: auto;
  `}

  ${mq.from.XXL`
    max-width: 6.25vw;
  `}
`

// Main styles
const Wrapper = styled.div`
  position: fixed;
  height: 10vh;
  z-index: ${zIndices.siteNavBar};
  top: 0;
  right: 0;
  left: 0;
  transform-origin: top center;
  transition: transform ${duration.slow}ms ${ease.default};
  transform: translate3d(0, 0, 0);
`

const HeaderText = styled.span`
  font-family: ${fonts.primary};
  color: ${colors.white};
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  transition: 0.5s;
  vertical-align: super;
  line-height: 3;

  &:hover {
    color: ${colors.accent1};
  }

  ${mq.from.XS`
    font-size: 18px;
  `}

  ${mq.from.S`
    font-size: 20px;
  `}

  ${mq.from.M`
    font-size: 22px;
  `}

  ${mq.from.L`
    line-height: 2.5;
  `}
`

const Container = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;

  ${mq.from.XXL`
    padding-top: 30px;
    padding-bottom: 30px;
  `}
`

const LogoLink = styled.a`
  line-height: 0;

  ${mq.from.XXL`
    height: 100%;
  `}
`

const Logo = styled(LogoComponent)`
  ${sharedSVGStyles};
  margin-right: 10px;
`
