import React from "react"
import styled from "styled-components"

// Components
import { Row, Col } from "./layout/Grid"
import Container from "./layout/Container"
import Divider from "./layout/Divider"
import content from "../../static/content"

// Styles
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"
import { fonts } from "../styles/fonts"

interface ILesson {
  title: string
  text: {
    html: string
  }
  image: {
    src: string
    alt: string
  }
}

const TipsBlock = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col m={14} mOffset={1}>
          {content.homepage.tips.title && (
            <Header>
              <Divider color={colors.white} />
              <Title>{content.homepage.tips.title}</Title>
            </Header>
          )}

          {content.homepage.tips.lessons.length > 0 && (
            <List>
              {content.homepage.tips.lessons.map(
                (lesson: ILesson, index: number) => (
                  <Lesson key={index}>
                    <ImageWrapper>
                      <RowImage src={lesson.image.src} alt={lesson.image.alt} />
                    </ImageWrapper>
                    <TextWrapper>
                      <TipsTitle>{lesson.title}</TipsTitle>
                      <TipsText
                        dangerouslySetInnerHTML={{
                          __html: lesson.text.html,
                        }}
                      />
                    </TextWrapper>
                  </Lesson>
                )
              )}
            </List>
          )}
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

export default TipsBlock

// Main styles
export const Wrapper = styled.div`
  margin-bottom: 50px;
`

const RowImage = styled.img`
  height: 75px;
  width: 75px;
  object-fit: contain;
  object-position: center;
`

const Lesson = styled.div`
  margin: 25px 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mq.from.M`
   flex-direction: row;
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;

  ${mq.from.M`
    width: 20%;
  `}
`

const TextWrapper = styled.div`
  width: 100%;

  ${mq.from.M`
    width: 80%;
  `}
`

const Header = styled.div``

const Title = styled.h2`
  margin-bottom: 25px;
`

export const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
`

const TipsTitle = styled.h3`
  font-size: 1.5em;
  font-family: ${fonts.primary};
`

const TipsText = styled.p`
  margin: 0;
  font-family: ${fonts.primary};
`
