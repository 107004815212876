import React from "react"

// Types
import { IIcon } from "../utils/icon"

const SocialInstagramSVG = ({ className, width, height, color }: IIcon) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 120 120"
    fill={color}
  >
    <g fillRule="evenodd">
      <path fill="none" d="M0 0h120v120H0z" />
      <path d="M78.404 30H41.596C35.203 30 30 35.2 30 41.596v36.808C30 84.8 35.203 90 41.596 90h36.808C84.797 90 90 84.8 90 78.404V41.596C90 35.2 84.797 30 78.404 30zM51.438 53.84c1.92-2.66 5.038-4.402 8.562-4.402 3.524 0 6.643 1.743 8.562 4.403 1.252 1.735 2 3.859 2 6.159 0 5.82-4.738 10.559-10.562 10.559-5.82 0-10.559-4.738-10.559-10.559 0-2.3.748-4.424 1.997-6.16zm21.413-12.964A4.494 4.494 0 0176.499 39c1.502 0 2.83.743 3.649 1.876A4.47 4.47 0 0181 43.501C81 45.98 78.981 48 76.5 48S72 45.981 72 43.5c0-.98.319-1.884.85-2.624zm11.304 37.528a5.756 5.756 0 01-5.75 5.75h-36.81a5.756 5.756 0 01-5.75-5.75V41.591a5.756 5.756 0 015.75-5.75h36.81a5.756 5.756 0 015.75 5.75v36.813zM60 43.587c-6.87 0-12.767 4.248-15.206 10.253h.012a16.294 16.294 0 00-1.206 5.77l-.004.385a16.35 16.35 0 00.008.532l-.003-.142a16.292 16.292 0 001.205 5.77h-.012C47.233 72.16 53.13 76.408 60 76.408c6.873 0 12.77-4.248 15.21-10.253h-.012a16.424 16.424 0 001.21-6.16c0-2.172-.441-4.25-1.21-6.154h.012C72.77 47.834 66.873 43.586 60 43.586z" />
    </g>
  </svg>
)

export default SocialInstagramSVG
