import React from "react"

// Types
import { IIcon } from "../utils/icon"

const SocialLinkedinSVG = ({ className, width, height, color }: IIcon) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 40 40"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path fill="none" d="M0 0h40v40H0z" />
      <path d="M14.767 16.52h-4.5v13.523h4.5V16.52zm.297-4.184C15.034 11.01 14.086 10 12.546 10 11.006 10 10 11.01 10 12.336c0 1.299.977 2.338 2.488 2.338h.029c1.57 0 2.547-1.04 2.547-2.338zM31 22.289c0-4.154-2.22-6.087-5.182-6.087-2.39 0-3.46 1.313-4.058 2.233V16.52H17.26c.06 1.269 0 13.524 0 13.524h4.501V22.49c0-.404.03-.807.149-1.097.325-.807 1.066-1.643 2.31-1.643 1.629 0 2.28 1.24 2.28 3.057v7.235h4.5L31 22.29z" />
    </g>
  </svg>
)

export default SocialLinkedinSVG
