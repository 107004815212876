import React from "react"
import styled from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { fonts } from "../styles/fonts"

// Components
import { Row, Col } from "./layout/Grid"
import SocialMediaIcons from "./SocialMediaIcons"
import InfoFooter from "./InfoFooter"

const Footer = () => {
  return (
    <>
      <InfoFooter />

      <Wrapper>
        <div>
          <Row>
            <Col m={16} mOffset={0}>
              <Heading>Want more?</Heading>
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col m={12} mOffset={2}>
              {/* Inverse the theme of the icons */}
              <SocialMediaIcons />
            </Col>
          </Row>
        </div>
      </Wrapper>
      <SlimWrapper>
        <FooterCol m={16} mOffset={0}>
          &copy; {new Date().getFullYear()} Jungle Minds. All rights reserved.
        </FooterCol>
      </SlimWrapper>
    </>
  )
}

export default Footer

// Main styles
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.black};
  color: ${colors.white};
  text-align: center;
`

const Heading = styled.h3`
  ${textStyles.headingS};
  margin-bottom: 10px;
`

// Main styles
const SlimWrapper = styled(Wrapper)`
  padding: 0vw 5vw;
  color: ${colors.white};
  font-family: ${fonts.primary};

  a {
    color: ${colors.white};
    text-decoration: none;
    margin-left: 2vw;
    :hover {
      color: ${colors.accent1};
    }
    ${mq.from.XXS`
      display: block;
      margin: 0;
    `}
    ${mq.from.S`
      display: inline;
      margin-left: 2vw;
    `}
  }
`

const FooterCol = styled(Col)`
  padding: 3vh 0;
  text-align: left;
  ${mq.from.XXS`
      text-align: center;
    `}
  ${mq.from.S`
      text-align: left;
    `}
`
