import React, { ReactNode } from "react"
import styled, { css } from "styled-components"

// Styles
import { mq } from "../../styles/utils/mq"

// Utils
import UseWindowSize from "../utils/UseWindowSize"

const calcColPerc = (columns: number, totalColumns: number) => {
  const perc = Number((columns / totalColumns).toFixed(5)) * 100
  return `${perc >= 100 ? 100 : perc}%;`
}

const generateColWidth = (props: IColContainer) =>
  Object.keys(mq.sizes).map(
    size =>
      (props as any)[size.toLowerCase()] !== undefined &&
      css`
        ${mq.from[size]`
        width:${calcColPerc(
          (props as any)[size.toLowerCase()],
          props.totalColumns
        )}
    `}
      `
  )

const generateColOffset = (props: IColContainer) =>
  Object.keys(mq.sizes).map(
    size =>
      (props as any)[`${size.toLowerCase()}Offset`] !== undefined &&
      css`
        ${mq.from[size]`
          margin-left:${calcColPerc(
            (props as any)[`${size.toLowerCase()}Offset`],
            props.totalColumns
          )}
      `}
      `
  )

interface IGridProps {
  xxs?: number
  xs?: number
  s?: number
  m?: number
  l?: number
  xl?: number
  xxl?: number
  xxsOffset?: number
  xsOffset?: number
  sOffset?: number
  mOffset?: number
  lOffset?: number
  xlOffset?: number
  xxlOffset?: number
}

interface IColProps extends IGridProps {
  children: ReactNode
}

const Col: React.FC<IColProps> = ({ children, ...rest }) => {
  if (typeof window === "undefined") {
    return null
  }

  const { width: windowWidth } = UseWindowSize()
  const totalColumns = windowWidth && windowWidth < mq.sizes.M ? 8 : 16

  return (
    <ColContainer {...rest} totalColumns={totalColumns}>
      {children}
    </ColContainer>
  )
}

interface IColContainer extends IGridProps {
  totalColumns: number
}

const ColContainer = styled.div<IColContainer>`
  width: 100%;
  ${props => generateColWidth(props)};
  ${props => generateColOffset(props)};
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export { Col, Row }
