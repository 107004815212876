import { css } from "styled-components"

// Styling
import { fonts, fontWeights } from "./fonts"
import { mq } from "./utils/mq"
import colors from "./colors"

// Shared styles
const sharedHeadingStyles = css`
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.bold};
  text-rendering: optimizeLegibility;
`

/**
 * All the fonts are scalable as per the following rules:
 * - From vw 320px - 1920px, the sizes are controlled per each breakpoint.
 * - From 1920px and up, the sizes all scale as a factor of the --base-scalable-font-size
 * which ensures that all the fonts with grow linearly with the window size
 * 'til infinity and beyond.
 *
 * Additionally, all fonts have simple px fallbacks in case calculations are not supported for some reason.
 *
 * More info on the formula used to calculate font ranges below:
 * https://css-tricks.com/snippets/css/fluid-typography/
 */

// Main styles
export default {
  headingXXL: css`
    ${sharedHeadingStyles};
    /* 48px - 96px */
    font-size: 48px;
    font-size: calc(48px + (96 - 48) * ((100vw - 320px) / (768 - 320)));
    line-height: 1;

    /* 96px - 160px */
    ${mq.from.M`
      font-size: 96px;
      font-size: calc(96px + (160 - 96) * ((100vw - 768px) / (1280 - 768)));
      letter-spacing: -0.8px;
    `}

    /* 160px - 240px */
    ${mq.from.XL`
      font-size: 160px;
      font-size: calc(160px + (240 - 160) * ((100vw - 1280px) / (1920 - 1280)));
      letter-spacing: -1.33px;
    `}

    /* 240px+ */
    ${mq.from.XXL`
      font-size: 240px;
      letter-spacing: -2px;
    `}
  `,
  headingXL: css`
    ${sharedHeadingStyles};
    /* 48px - 64px */
    font-size: 48px;
    font-size: calc(48px + (64 - 48) * ((100vw - 320px) / (768 - 320)));
    line-height: 1;

    /* 64px - 104px */
    ${mq.from.M`
      font-size: 64px;
      font-size: calc(64px + (104 - 64) * ((100vw - 768px) / (1280 - 768)));
      letter-spacing: -0.6px;
    `}

    /* 104px - 160px */
    ${mq.from.XL`
      font-size: 104px;
      font-size: calc(104px + (160 - 104) * ((100vw - 1280px) / (1920 - 1280)));
      letter-spacing: -1px;
    `}

    /* 120px+ */
    ${mq.from.XXL`
      font-size: 160px;
      letter-spacing: -1.5px;
    `}
  `,
  headingL: css`
    ${sharedHeadingStyles};
    /* 36px - 48px */
    font-size: 36px;
    line-height: 1;

    /* 48px - 80px */
    ${mq.from.M`
      font-size: 48px;
      letter-spacing: -0.8px;
    `}

    /* 80px - 120px */
    ${mq.from.XL`
      font-size: 80px;
      letter-spacing: -0.67px;
    `}

    /* 120px+ */
    ${mq.from.XXL`
      font-size: 120px;
      letter-spacing: -1px;
    `}
  `,
  headingM: css`
    ${sharedHeadingStyles};
    /* 24px - 32px */
    font-size: 24px;
    line-height: 1.125;

    /* 32px - 52px */
    ${mq.from.M`
      font-size: 32px;
      letter-spacing: -0.3px;
    `}

    /* 52px - 80px */
    ${mq.from.XL`
      font-size: 52px;
      letter-spacing: -0.5px;
    `}

    /* 80px+ */
    ${mq.from.XXL`
      font-size: 80px;
      letter-spacing: -0.75px;
    `}
  `,
  headingS: css`
    ${sharedHeadingStyles};
    /* 18px - 24px */
    font-size: 18px;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (768 - 320)));
    line-height: 1.125;

    /* 24px - 40px */
    ${mq.from.M`
      font-size: 24px;
      letter-spacing: -0.27px;
    `}

    /* 40px - 60px */
    ${mq.from.XL`
      font-size: 40px;
      letter-spacing: -0.5px;
    `}

    /* 80px+ */
    ${mq.from.XXL`
      font-size: 60px;
      letter-spacing: -0.67px;
    `}
  `,
  body: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    line-height: 1.5;

    /* 18 - 30px */
    ${mq.from.XL`
      font-size: 18px;
      font-size: calc(18px + (30 - 18) * ((100vw - 1280px) / (1920 - 1280)));
    `}

    /* 30px + */
    ${mq.from.XXL`
      font-size: 30px;
      line-height: 1.4;
    `}
  `,
  hyperlink: css`
    color: ${colors.black};
    text-decoration: none;

    span {
      position: relative;
    }

    :hover {
      text-decoration: underline;
    }
  `,
}
