import React from "react"
import styled from "styled-components"

// Styling
import colors from "../../styles/colors"

interface IProps {
  className?: string
  color?: string
}

const Divider = ({ className, color = colors.black }: IProps) => (
  <Elem className={className} color={color} />
)

// Main styles
const Elem = styled.div<{ color: string }>`
  border-top: 1px solid ${({ color }) => color};
`

export default Divider
